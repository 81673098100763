import React, { useState } from "react";
import pic0 from "../../images/toastie.jpg";
import pic1 from "../../images/daisy.jpg";
import pic2 from "../../images/sitstay.jpg";
import "./Slideshow.css";
import { useSpring, animated } from "react-spring";

const Slideshow = () => {
  const [slide, setSlide] = useState(0);
  const pics = [pic0, pic1, pic2];
  const alts = [
    "Our dog, Toastie, being her usual goofy self",
    "Daisy doodle taking a break in the grass",
    "Practicing our six-dog sit & stay... say cheese!",
  ];

  const calc = (x, y) => [
    -(y - window.innerHeight / 2) / 20,
    (x - window.innerWidth / 2) / 20,
    1.1,
  ];
  const trans = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  const getImage = () => {
    return <animated.div
      className="Slideshow-picture"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ transform: props.xys.interpolate(trans), backgroundImage: `url(${pics[slide]})` }}
    />;
  };
  return (
    <div className="Slideshow-Wrapper">
      <header className="Slideshow-Label">
        <div></div>
        <h3>From the Pack</h3>
      </header>
      {getImage()}
      <section className="Thumbnail-Wrapper">
        {pics.map((cur, index) => {
          return (
            <img
              src={cur}
              alt={alts[index]}
              className="Slideshow-thumbnail"
              key={index}
              onClick={() => setSlide(index)}
            />
          );
        })}
      </section>
    </div>
  );
};

export default Slideshow;
