import React from "react";
import "./pageProvider.css";

// Pages
import HomePage from "./HomePage";
import AboutPage from "./AboutPage";
import ServicesPage from "./ServicesPage";
import ContactPage from './ContactPage';

const pageProvider = (props) => {
  const getMainContent = () => {
    switch (props.location) {
      case "home":
        return <HomePage />;
      case "about":
        return <AboutPage />;
      case "services":
        return <ServicesPage />;
      case "contact":
        return <ContactPage />;
      default:
        return <HomePage />;
    }
  };

  return <div className="PageWrapper">{getMainContent()}</div>;
};

export default pageProvider;
