import React, { useState } from "react";

// Custom Components
import Navbar from "./components/navbar/Navbar";
import PageProvider from "./pages/pageProvider";

import "./App.css";

function App() {
  const [location, setLocation] = useState("home");

  return (
    <div className="App">
      <header>
        <section>
          <h1>Wag 'n Wander</h1>
          <h2>Dog walks worth wagging about</h2>
        </section>
        <Navbar location={location} setLocation={setLocation} />
      </header>
      <main>
        <PageProvider location={location} />
      </main>
    </div>
  );
}

export default App;
