import React from "react";
import "./pageProvider.css";
import Slideshow from '../components/slideshow/Slideshow';
import Instagram from '../images/instagram.png'

const HomePage = () => {
  return (
    <div className="HomePageWrapper">
      <section className="WithBackground2">
        <h1>Walk with us!</h1>
        <p>
          Whether your four-legged companion needs more physical or mental exercise, 
          pack socialization or one-on-one time, we strive to provide the best care 
          for each individual dog and their humans.<br /><br />
          Our goal is to bring your dog home at the end of the day feeling fulfilled and tired!
        </p>
      </section>
      <br />
      <Slideshow />
      <a href="https://www.instagram.com/wag.n.wander/" id="Instagram">
        <img src={Instagram} alt="Wag 'n Wander on Instagram" />
        <h2>Wag 'n Wander<br/>on<br />Instagram</h2>
      </a>
      <section className="WithBackground">
        <h1>Servicing Central & SW Calgary:</h1>
        <p>
          Sarcee Trail to Blackfoot trail <br />
          Shawnessy Blvd. to Bow Trail
        </p>
      </section>
    </div>
  );
};

export default HomePage;
