import React from "react";
import "./ServiceCard.css";

const ServiceCard = (props) => {
  return (
    <div className="ServiceCard-Wrapper">
      <section>
        <h1>{props.title}</h1>
        <h2>{props.price}</h2>
      </section>
      <main>
        <h3>{props.description}</h3>
        <button
          onClick={(e) => {
            e.preventDefault();
            props.toggleModal(true);
            props.setModalProps({ title: props.title, price: props.price, productNumber: props.productNumber });
          }}
        >
          Request A Walk
        </button>
      </main>
    </div>
  );
};

export default ServiceCard;
