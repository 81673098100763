// https://css-tricks.com/stay-dry-using-axios-for-api-requests/

import axios from 'axios'

const defaultURL = "https://uglnxn7ow4.execute-api.ca-central-1.amazonaws.com/default"
let token = ""

/*
	Creating customizable instance of axios
*/
const createAxiosInstance = (options) => {
	let headers = {
		Pragma: "no-cache",
		...options.headers
	}

	let config = {
		baseURL: options.baseURL ? options.baseURL : defaultURL,
		timeout: 30000,
		headers
	}

	let instance = axios.create(config)

	return instance;
}

/*
	Building interceptors for specific status codes
*/


// const unauthorizedResponseInterception = response => {
// 	if (response.status === 401) {
// 		// Handle unauthorized - remove cookies etc. 
// 	}
// 	console.log(response)
// 	return response;
// }

/*
	Handling requests
*/

export const REQUEST = {
	GET: 'GET',
	POST: 'POST',
	PATCH: 'PATCH',
	DELETE: 'DELETE'
}

export const axiosRequest = (type, path, data, config = {}) => {
	if (!type) {
		return Promise.reject({ status: 400, message: "A request type must be supplied. [GET, POST, PUT]" })
	}
	if (!path) {
		return Promise.reject({ status: 400, message: "A request path must be supplied." })
	}

	// Modify axios configuration
	let options = { ...config };
	if (config.baseURL) {
		options.baseURL = config.baseURL;
	}

	// generate request
	switch (type.toUpperCase()) {
		case "GET":
			return createAxiosInstance(options)
				.get(path, { params: data })
				.then(onSuccess)
				.catch(onError)
		case 'POST':
			return createAxiosInstance(options)
				.post(path, data)
				.then(onSuccess)
				.catch(onError)
		// case 'PATCH':
		// 	return createAxiosInstance(options)
		// 		.patch(path, data, { withCredentials: true })
		// 		.then(onSuccess)
		// 		.catch(onError)
		// case 'DELETE':
		// 	return createAxiosInstance(options)
		// 		.delete(path, { params: data, withCredentials: true })
		// 		.then(onSuccess)
		// 		.catch(onError)
		default:
			return Promise.reject({ status: 400, message: "Invalid request type. Must be one of GET, POST, PATCH" })
	}
}

const onSuccess = result => { return result.data }
// const onError = err => { throw err.response.data }
const onError = err => {
	console.log(err)
	throw { message: "Unknown error occurred" }
}