import React, { useState } from "react";
import ServiceModal from "../components/servicemodal/ServiceModal";
import "./pageProvider.css";

// Custom Components
import ServiceCard from "../components/servicecard/ServiceCard";

const ServicesPage = () => {
  const [openModal, toggleModal] = useState(false);
  const [modalProps, setModalProps] = useState({title: undefined, price: undefined})
  return (
    <div className="PageContentWrapper">
      <ServiceCard
        title="Off-Leash Walk"
        price="$22"
        productNumber="1000"
        description="1 hour walk with the pack in an off-leash dog park. Dogs will work on recall, socialization, and focus. Available weekday mornings or afternoons."
        toggleModal={toggleModal}
        setModalProps={setModalProps}
      />
      <ServiceCard
        title="Full-Day Care"
        price="$40"
        productNumber="1001"
        description="Join both AM & PM off-leash pack walks for extra exercise, reinforcing skills and socializing. 1 hour walk for each. Available weekdays."
        toggleModal={toggleModal}
        setModalProps={setModalProps}
      />

      <ServiceCard
        title="AM On-Leash Walk"
        price="$22"
        productNumber="1002"
        description="30 minute walk with a smaller pack on an on-leash route. Dogs work on their on-leash manners, reactivity and focus."
        toggleModal={toggleModal}
        setModalProps={setModalProps}
      />
      <ServiceModal
        title="Off-Leash Walk"
        price="22"
        openModal={openModal}
        toggleModal={toggleModal}
        modalProps={modalProps}
      />
    </div>
  );
};

export default ServicesPage;
