import React from "react";
import styles from "./Navbar.module.css";

const Navbar = (props) => {
  const handleClick = (page) => {
    props.setLocation(page);
  };
  return (
    <div className={styles.Wrapper}>
      <button
        className={
          props.location === "home" ? styles.Selected : styles.Deselected
        }
        onClick={() => handleClick("home")}
      >
        Home
      </button>
      <button
        className={
          props.location === "about" ? styles.Selected : styles.Deselected
        }
        onClick={() => handleClick("about")}
      >
        About
      </button>
      <button
        className={
          props.location === "services" ? styles.Selected : styles.Deselected
        }
        onClick={() => handleClick("services")}
      >
        Services
      </button>
      <button
        className={
          props.location === "contact" ? styles.Selected : styles.Deselected
        }
        onClick={() => handleClick("contact")}
      >
        Contact
      </button>
    </div>
  );
};

export default Navbar;
