import React, { useState } from "react";
import "./pageProvider.css";
import "./contactForm.css";
import { axiosRequest, REQUEST } from "../utils/Request";

const ContactPage = () => {
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    switch (e.target.name) {
      case "clientName":
        setClientName(e.target.value);
        break;
      case "clientEmail":
        setClientEmail(e.target.value);
        break;
      case "subject":
        setSubject(e.target.value);
        break;
      case "message":
        setMessage(e.target.value);
        break;
      default:
        break;
    }
  };

  const finalSubmit = (values) => {
    axiosRequest(REQUEST.POST, '/walkRequest2', values)
      .then(res => {
        if (res.errors && res.errors.length > 0) alert("Sorry! The request wasn't sent. Please try again!");
        return;
      })
      .catch(err => {
        alert("Sorry! The request wasn't sent. Please try again!");
      });
    // let headers = {
    //   "Access-Control-Allow-Origin":
    //     "http://www.wagnwanderyyc.com,http://wagnwanderyyc.com",
    //   "Access-Control-Allow-Headers":
    //     "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
    //   "Access-Control-Request-Method": "DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT",
    //   "Content-Type": "text/json",
    // };
    // // OLD URL
    // // const url = `https://wgx8fcy0uc.execute-api.ca-central-1.amazonaws.com/Prod/api/Mail/ContactEmail`
    // const url = `https://k0bjgwpb38.execute-api.ca-central-1.amazonaws.com/default/walkRequest2`
    // fetch(
    //   url,
    //   {
    //     method: "POST",
    //     headers: headers,
    //     body: JSON.stringify(values),
    //   }
    // )
    //   .then((res) => {
    //     if (res.errors && res.errors.length > 0)
    //       alert("Sorry! The email wasn't sent. Please try again!");
    //     return;
    //   })
    //   .catch((err) => {
    //     alert(err);
    //   });
  };

  const submit = (e) => {
    e.preventDefault();
    let newErrors = errorCheck();
    if (newErrors.length > 0) {
      alert(`Please fill in all fields. Missing: ${newErrors.toString()}`);
      return;
    }

    setSubmitted(true);

    let values = {
      FullName: clientName,
      Email: clientEmail,
      PhoneNumber: '555-555-5555',
      Subject: subject,
      Message: message,
    };

    finalSubmit(values);

    alert("Thanks for sending us a message. We'll get back to you asap!");
    // Call API to send email here
    setTimeout(() => {
      clearForm();
      setSubmitted(false);
    }, 3000);
  };

  const clearForm = () => {
    setSubject("");
    setMessage("");
  };

  const errorCheck = () => {
    let newErrors = [];
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (clientName === "") newErrors.push("Name ");
    if (clientEmail === "") newErrors.push("Email ");
    else if (!re.test(clientEmail)) newErrors.push("Email ");
    if (subject === "") newErrors.push("Subject ");
    if (message === "") newErrors.push("Message ");

    return newErrors;
  };

  return (
    <div className="ContactPageContentWrapper">
      <h2 className="Header">
        Have a question? <br />
        Send us an email
      </h2>
      <form className="ContactForm">
        <section>
          <label>Name:</label>
          <input
            type="text"
            onChange={handleChange}
            name="clientName"
            value={clientName}
            placeholder="First & Last Name"
            required
          />
        </section>
        <section>
          <label>Email:</label>
          <input
            type="text"
            onChange={handleChange}
            name="clientEmail"
            value={clientEmail}
            placeholder="user@domain.com"
            required
          />
        </section>
        <section>
          <label>Subject:</label>
          <input
            type="text"
            onChange={handleChange}
            name="subject"
            value={subject}
            placeholder="Email Subject"
            className="FullWidth"
            required
          />
        </section>
        <section>
          <label>Email:</label>
          <textarea
            type="textarea"
            onChange={handleChange}
            name="message"
            value={message}
            placeholder="Message"
            className="FullWidth"
            id="messageBox"
            required
          />
        </section>
        <section id="submitButton">
          <button onClick={submit} disabled={submitted}>
            {submitted ? "Email Sent" : "Send"}
          </button>
        </section>
      </form>
    </div>
  );
};

export default ContactPage;
