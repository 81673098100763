import React from "react";
import "./pageProvider.css";
import { useSpring, animated } from "react-spring";

import "./aboutPage.css";

const AboutPage = () => {
  const calc = (x, y) => [
    -(y - window.innerHeight / 2) / 20,
    (x - window.innerWidth / 2) / 20,
    1.1,
  ];
  const trans = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }));
  return (
    <div className="PageContentWrapper">
      <header className="AboutUs-Header">
        <h1 id="AboutUs-h1">About Us: </h1>
        <h2 id="AboutUs-h2">Brenna & Brett</h2>
        <p id="AboutUs-p">
          Brenna started working directly with dogs as a behaviour specialist in
          2013, undergoing an extensive training program. Over the past 7 years
          she has worked with hundreds of dogs, across all breeds, ages, and
          temperments. Her focus now is on helping dogs get the exercise and
          stimulation they need through structured on and off leash walks,
          reinforcing skills and commands to maintain a healthy, happy
          relationship with our furry companions.
        </p>
        <p id="AboutUs-p">
          Brett has been helping Brenna care for our clients pets while managing
          the more businessy aspects of our venture. Together, Brett and Brenna
          have raised and cared for several of their own dogs who regularly join
          in on pack walks.
        </p>
      </header>
      <h2 id="PupsTitle">Our Pups</h2>
      <section className="AboutPage-Section">
        <section className="DogCard">
          <h3>Sadie</h3>
          <h4>9 Year Old - Chocolate Lab</h4>
        </section>
        <animated.div
          className="AboutPage-Dogs"
          id="sadie"
          onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
          onMouseLeave={() => set({ xys: [0, 0, 1] })}
          style={{
            transform: props.xys.interpolate(trans),
          }}
        />
        <p>
          Sadie is our first puppy, our baby. She's the leader of our pack and a
          pillar in our lives.
        </p>
      </section>
      <section className="AboutPage-Section">
        <section className="DogCard">
          <h3>Toaster</h3>
          <h4>1 Year Old - Husky Mix</h4>
        </section>
        <animated.div
          className="AboutPage-Dogs"
          id="toastie"
          onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
          onMouseLeave={() => set({ xys: [0, 0, 1] })}
          style={{
            transform: props.xys.interpolate(trans),
          }}
        />
        <p>
          Toastie fell into our lap as a rescued pup at just 8 weeks old. We
          immediately knew she would be one of the family and continues to
          entertain us with her bouncy, loving nature.
        </p>
      </section>
      <section className="AboutPage-Section">
        <section className="DogCard">
          <h3>Dundee</h3>
          <h4>15 Year Old - Bichon Shitzu</h4>
        </section>
        <animated.div
          className="AboutPage-Dogs"
          id="dundee"
          onMouseMove={({ clientX: x, clientY: y }) => {
            set({ xys: calc(x, y) });
          }}
          onMouseLeave={() => set({ xys: [0, 0, 1] })}
          style={{
            transform: props.xys.interpolate(trans),
          }}
        />
        <p>
          This lovable furball is sadly no longer with us. His quirky energy was
          always welcoming and brought a smile to us whenever we came home. We
          miss you, Dundee.
        </p>
      </section>
    </div>
  );
};

export default AboutPage;
