import React, { useState } from "react";
import { axiosRequest, REQUEST } from "../../utils/Request";

import "./ServiceModal.css";

const ServiceModal = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [getDate, setDate] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");

  const baseMessage = "Hi! I'd like to book a walk for...";
  const [message, setMessage] = useState(baseMessage);

  const finalSubmit = values => {
    axiosRequest(REQUEST.POST, '/walkRequest2', values)
      .then(res => {
        if (res.errors && res.errors.length > 0) alert("Sorry! The request wasn't sent. Please try again!");
        return;
      })
      .catch(err => {
        alert("Sorry! The request wasn't sent. Please try again!");
      });
    // let headers = {
    //     'Access-Control-Allow-Origin': 'http://www.wagnwanderyyc.com,http://wagnwanderyyc.com',
    //     'Access-Control-Allow-Headers': 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
    //     'Access-Control-Request-Method': 'DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT',
    //     'Content-Type': 'text/json'
    // }
    // // OLD URL
    // // const url = `https://wgx8fcy0uc.execute-api.ca-central-1.amazonaws.com/Prod/api/Mail/RequestWalk`
    // const url = `https://k0bjgwpb38.execute-api.ca-central-1.amazonaws.com/default/walkRequest2`
    // fetch(url, {
    //     method: 'POST',
    //     headers: headers,
    //     body: JSON.stringify(values)
    // })
    //     .then(res => {
    //         if (res.errors && res.errors.length > 0) alert("Sorry! The request wasn't sent. Please try again!");
    //         return;
    //     })
    //     .catch(err => {
    //         alert("Sorry! The request wasn't sent. Please try again!");
    //     });
  }

  const submitRequest = (e) => {
    e.preventDefault();
    let newErrors = checkErrors();
    if (newErrors.length > 0) {
      alert(`Please fill out all fields. Missing: ${newErrors.toString()}`);
      return;
    }

    setSubmitted(true);
    let values = {
      FullName: clientName,
      Dogs: '[no longer listed outside of message]',
      Date: getDate,
      Email: clientEmail,
      PhoneNumber: '555-555-5555',
      Message: message,
      ProductNumber: props.modalProps.productNumber
    }

    finalSubmit(values);

    setTimeout(() => {
      setSubmitted(false);
      props.toggleModal(false);
    }, 1500);
  };

  const checkErrors = () => {
    let newErrors = [];
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (clientName === "") newErrors.push("Name ");
    if (clientEmail === "") newErrors.push("Email ");
    else if (!re.test(clientEmail)) newErrors.push("Email ");
    return newErrors;
  }

  return (
    <div className={props.openModal ? "Modal-Wrapper" : "Modal-Hidden"}>
      <main>
        <h1>
          {props.modalProps.title} - {props.modalProps.price}
        </h1>
        <form>
          <label>Date: </label>
          <input
            type="date"
            value={getDate}
            onChange={(e) => setDate(e.target.value)}
          />
          <label>Name: </label>
          <input
            type="text"
            placeholder="First & Last Name"
            value={clientName}
            onChange={e => setClientName(e.target.value)}
            required
          />
          <label>Email: </label>
          <input
            type="email"
            placeholder="user@domain.com"
            value={clientEmail}
            data-label="Email"
            onChange={e => setClientEmail(e.target.value)}
            required
          />
          <label>Message: </label>
          <textarea
            placeholder=""
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <section className="Modal-Buttons">
            <button
              onClick={(e) => {
                e.preventDefault();
                props.toggleModal(false);
              }}
            >
              Cancel
            </button>
            <button
              style={{ backgroundColor: "#5e9c6c", color: "whitesmoke" }}
              onClick={e => submitRequest(e)}
              disabled={submitted}
            >
              {submitted ? "Sent" : "Send"}
            </button>
          </section>
        </form>
      </main>
    </div>
  );
};

ServiceModal.propTypes = {};

export default ServiceModal;
